import {
	BrowserModule,
	HAMMER_GESTURE_CONFIG,
	HammerGestureConfig
} from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";

import { ShareButtonsModule } from "@ngx-share/buttons";
import { ShareButtonsConfig, IShareButtons } from "@ngx-share/core";

import { Angulartics2Module } from "angulartics2";
import { environment } from "@environments/environment";

import { SharedModule } from "@shared/shared.module";

import { AppComponent } from "./app.component";

import { AppRoutingModule } from "./app.routing.module";
import { CraftCMSService } from "@shared/services/craft.service";
import { InterfaceService } from "@shared/services/interface.service";

import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { ScrollService } from "@shared/services/scroll.service";

import { AppRouteReuseStrategy } from "./app.routing.reuse-strategy";

registerLocaleData(localeDe, "de");

const shareButtonsMeta: IShareButtons = {
	copy: {
		text: "Link kopieren",
		extra: {
			successLabel: "kopiert!"
		}
	}
};
const shareButtonsConfig: ShareButtonsConfig = {
	include: ["facebook", "twitter", "google", "email", "copy"],
	theme: "custom-theme",
	gaTracking: true,
	title: "Philoneos",
	description: "",
	twitterAccount: "",
	prop: shareButtonsMeta
};

declare let Hammer: any;

export class CustomHammerConfig extends HammerGestureConfig {
	buildHammer(element: HTMLElement) {
		let mc = new Hammer(element, {
			touchAction: "pan-y"
		});
		return mc;
	}
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: "ng-philoneos" }),
		BrowserAnimationsModule,
		HttpClientModule,
		HttpClientJsonpModule,
		TransferHttpCacheModule,
		AppRoutingModule,
		ShareButtonsModule.withConfig(shareButtonsConfig),
		SharedModule.forRoot(),
		Angulartics2Module.forRoot({
			developerMode: environment.production ? false : true
		})
	],
	providers: [
		CraftCMSService,
		InterfaceService,
		ScrollService,
		{ provide: LOCALE_ID, useValue: "de" },
		{
			provide: RouteReuseStrategy,
			useClass: AppRouteReuseStrategy
		},
		{
			// hammer instantion with custom config
			provide: HAMMER_GESTURE_CONFIG,
			useClass: CustomHammerConfig
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
