import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Image } from "@shared/services/craft.service";

declare let globalSiteTitle: string;

@Injectable()
export class InterfaceService {
	private default_title = "Philoneos";

	constructor(private titleService: Title, private metaService: Meta) {
		this.titleService = titleService;
		this.metaService = metaService;
		if (!this.default_title) this.default_title = this.titleService.getTitle();
	}

	public getTitle(): string {
		return this.titleService.getTitle();
	}

	public setTitle(newTitle?: string) {
		if (!newTitle && typeof this.default_title !== "undefined") {
			newTitle = this.default_title;
		} else if (!newTitle) {
			newTitle = this.getTitle();
		}
		this.titleService.setTitle(this.decodeHtmlEntity(newTitle));
	}

	private decodeHtmlEntity(str) {
		return str.replace(/&#(\d+);/g, (match, dec) => {
			return String.fromCharCode(dec);
		});
	}

	public setSubTitle(newTitle: string) {
		if (typeof this.default_title !== "undefined") {
			newTitle = newTitle + " – " + this.default_title;
		}
		this.setTitle(decodeURI(newTitle));
	}

	public addMetaTag(_name: string, _content: string) {
		this.metaService.updateTag({ name: _name, content: _content });
	}

	// HELPER FUNCTIONS
	public setTabTitle(title?: string) {
		// console.log("setMetaTitle", title);
		title ? this.setSubTitle(title) : this.setTitle();
	}
	public setMetaTitle(title?: string) {
		title ? this.addMetaTag("og:title", title) : null;
	}
	public setMetaDescription(description?: string) {
		let _description = "";
		if (description) {
			_description = description || "";
		}
		const regex = /(<([^>]+)>)/gi;
		_description = _description.replace(regex, "");
		_description = _description.replace("[&hellip;]", "…");
		this.addMetaTag("description", _description);
	}
	public setMetaImage(image?: Image) {
		if (!image) return;

		this.addMetaTag("og:image", image.url);
		this.addMetaTag("og:image:width", image.width + "");
		this.addMetaTag("og:image:height", image.height + "");
		this.addMetaTag(
			"og:image:alt",
			image.altText ? image.altText : image.title
		);
	}

	public setActiveInterface(
		title?: string,
		metaTitle?: string,
		description?: string,
		image?: Image
	) {
		this.setTabTitle(title);
		this.setMetaTitle(metaTitle);
		this.setMetaDescription(description);
		this.setMetaImage(image);
	}
}
