import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class CraftCMSService {
	constructor(private http: HttpClient) {}

	getPage(endpoint: string): Observable<CraftPage | Error> {
		return this.http.get(environment.apiUrl + endpoint).pipe(
			map((res: CraftResponseObject) => {
				if (res.data.length > 0) {
					return res.data[0];
				} else {
					return new Error("Error 404 : page not found");
				}
			}),
			catchError(this.handleError)
		);
	}
	getNav(endpoint: string): Observable<CraftNavigation | Error> {
		return this.http.get(environment.apiUrl + endpoint).pipe(
			map((res: CraftResponseObject) => {
				if (res.data.length > 0) {
					return res.data[0];
				} else {
					return new Error("Error 404 : navigation not found");
				}
			}),
			catchError(this.handleError)
		);
	}

	handleError = error => {
		return throwError(error);
	};
}

/*
 *  General Craft Object
 */

interface CraftResponseObject {
	data: Array<CraftPage | CraftNavigation | any>;
}

export interface CraftPage {
	id: number;
	type: string;
	title: string;
	slug: string;
	path: string;
	uri: string;
	url: string;
	section: string;
	sectionSlug: string;
	metaTitle: string;
	metaDescription: string;
	metaImage: Image;
}

export interface CraftNavigation {
	title: string;
	slug: string;
	path: string;
	nodes: NavigationItem[];
}

/*
 *  Questionnaire Page
 */

export interface Questionnaire extends CraftPage {
	question: string;
	answers: Array<Question | Answer>;
}

export interface Question {
	type: "question";
	label: string;
	text: string;
	answers: Array<Question | Answer>;
}
export interface Answer {
	type: "answer";
	label: string;
	text: string;
	redirectTo: Link;
}

/*
 *	Navigation Item
 */
interface NavigationItem {
	type: string;
	elementId: number;
	entryType: string;
	url: string;
	text: string;
	target: string;
}

/*
 *  Narrative Page
 */

export interface NarrativePage extends CraftPage {
	intro: string;
	panels: Array<
		| CraftPanel
		| Cards
		| CollaboratorsList
		| ReferencesList
		| TeamCards
		| Testimonial
		| TextBlock
		| TextGrid
	>;
}

/*
 *  Blog Page
 */

export interface BlogPage extends CraftPage {
	itemsPerPage: number;
	newsItems: Array<NewsItem>;
	articles: Array<Article>;
}

/*
 *  Contact Page
 */

export interface ContactPage extends CraftPage {
	intro: string;
	addressName: string;
	address: Address;
	email: {
		text: string;
		address: string;
	};
	telephone: {
		text: string;
		number: string;
	};
	socialLinks: Array<SocialLink>;
	newsletterSignupText: string;
	newsletterPrivacyCheckText: string;
	newsletterPrivacyCheckLink: any;
	newsletterPrivacyCheckHtml: any;
	newsletterMailchimpId: string | number;
}

/*
 *  Info Page
 */

export interface InfoPage extends CraftPage {
	content: Array<TextBlock>;
}

/*
 *  Media & Events Page
 */

export interface MediaAndEventsPage extends CraftPage {
	mediaHeadline: string;
	mediaItems: Array<any>;
	eventsUpcomingHeadline: string;
	eventsUpcoming: Array<any>;
	eventsPastHeadline: string;
	eventsPast: Array<any>;
}

/*
 *  Panels
 */

export interface CraftPanel {
	type: string;
	headline?: string;
	// [key: string]: any;
}

export interface Cards extends CraftPanel {
	type: "cards";
	cards: Array<Card>;
}

export interface CollaboratorsList extends CraftPanel {
	type: "collaboratorsList";
	intro: string;
	colorScheme: "red" | "black" | string;
	collaborators: Array<Collaborator>;
}

export interface Images extends CraftPanel {
	type: "images";
	images: Array<Image>;
}
export interface Gallery extends CraftPanel {
	type: "gallery";
	intro: string;
	images: Array<Image>;
}

export interface ReferencesList extends CraftPanel {
	type: "referencesList";
	intro: string;
	colorScheme: "red" | "black" | string;
	references: Array<Reference>;
}

export interface ReferencesLogos extends CraftPanel {
	type: "referencesLogos";
	headline: string;
	references: Array<Reference>;
}

export interface TeamCards extends CraftPanel {
	type: "teamCards";
	intro: string;
	colorScheme: "red" | "black" | string;
	teamMembers: Array<TeamMember>;
}

export interface Testimonial extends CraftPanel {
	type: "testimonial";
	headline: string;
	intro: string;
	testimonies: Array<Testimony>;
}

export interface TextBlock extends CraftPanel {
	type: "textBlock";
	text: string;
}

export interface TextGrid extends CraftPanel {
	type: "textGrid";
	cells: Array<TextGridCell>;
}

/**
 * Single Interfaces
 */

export interface Address {
	street: string;
	street2: string;
	postalCode: string;
	city: string;
	region: string;
	country: string;
	coords: {
		latitude: number;
		longitude: number;
	};
}

export interface Card {
	link: Link;
	front: {
		headline: string;
		caption: string;
		text: string;
	};
	back?: {
		headline: string;
		caption: string;
		text: string;
	};
	colorScheme: "red" | "black" | string;
	cellLayout: "column" | "row" | string;
}

export interface Collaborator {
	id: number;
	type: string;
	title: string;
	slug: string;
	section: string;
	sectionSlug: string;
	path: string;
	portrait: Image;
	nameTitle: string;
	firstName: string;
	lastName: string;
	companyName: string;
	descriptive: Array<Topic>;
}

export interface Image {
	title: string;
	type: string;
	filename: string;
	url: string;
	altText: string;
	width: number;
	height: number;
	src: string;
	srcset: string;
}

export interface Link {
	type: string;
	elementId: number;
	url: string;
	text: string;
	target?: string;
}

export interface Reference {
	id: number;
	type: string;
	title: string;
	slug: string;
	section: string;
	sectionSlug: string;
	path: string;
	caption: string;
	text: string;
	logo: Image;
}
export interface SocialLink {
	label: string;
	handle: string;
	url: string;
}

export interface TeamMember {
	id: number;
	type: string;
	title: string;
	slug: string;
	section: string;
	sectionSlug: string;
	path: string;
	portrait: Image;
	nameTitle: string;
	firstName: string;
	lastName: string;
	role: string;
	description: string;
	email: string;
}

export interface Testimony {
	id: number;
	type: string;
	title: string;
	slug: string;
	section: string;
	sectionSlug: string;
	path: string;
	portrait: Image;
	nameTitle: string;
	firstName: string;
	lastName: string;
	introduction: string;
	text: string;
}

export interface TextGridCell {
	type: "textGridCell";
	headline: string;
	text: string;
	colorScheme: "red" | "black" | string;
	cellLayout: "column" | "row" | string;
}

export interface Topic {
	type: "topic";
	headline: string;
	text: string;
}

/**
 * NewsItem
 */
export interface NewsItem {
	id: number;
	type: "newsItem";
	title: string;
	slug: string;
	section: string;
	sectionSlug: string;
	path: string;
	postDate: Date;
	expiryDate: Date;
}
/**
 * Article
 */
export interface Article extends CraftPage {
	pinned: boolean;
	headline: string;
	publicationTitle: string;
	publicationUrl: string;
	postDate: Date;
	authors: Array<TeamMember | Collaborator>;
	downloadFile: File;
	content: Array<TextBlock | Images>;
	backUri: string;
}

export interface File {
	id: number;
	type: "file";
	url: string;
	path: string;
	folderPath: string;
	kind: string;
	title: string;
	filename: string;
	extension: string;
	mimeType: string;
	link: string;
	filesize: string;
	filesizeString: string;
}
