import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";

import { CheckPlatformService } from "@shared/services/check-platform.service";
import { CheckMobileService } from "@shared/services/check-mobile.service";

import { DynamicInnerHtmlDirective } from "@shared/directives/dynamic-inner-html.directive";
import { SortByPipe } from "./pipes/sort-by.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { NgJoinPipeModule } from "angular-pipes";

import { IconEyeComponent } from "./components/icons/icon-eye/icon-eye.component";
// import { DeferLoadModule } from "./directives/defer-load/defer-load.module";

const MODULES = [
	CommonModule,
	RouterModule,
	ReactiveFormsModule,
	NgJoinPipeModule
	// DeferLoadModule
];

const PIPES: any = [SafePipe, SortByPipe];

const COMPONENTS: any = [DynamicInnerHtmlDirective, IconEyeComponent];

const PROVIDERS: any = [CheckMobileService, CheckPlatformService];

@NgModule({
	imports: [...MODULES],
	declarations: [...PIPES, ...COMPONENTS],
	exports: [...MODULES, ...PIPES, ...COMPONENTS]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule,
			providers: [...PROVIDERS]
		};
	}
}
