import { Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent } from "rxjs";

import { CheckPlatformService } from "@shared/services/check-platform.service";

@Injectable()
export class CheckMobileService {
	private mobileSate = new BehaviorSubject<boolean>(false);

	constructor(private checkPlatform: CheckPlatformService) {
		this.checkIfMobile();
		if (this.checkPlatform.isPlatformBrowser) {
			fromEvent(window, "resize").subscribe(() => this.checkIfMobile());
		}
	}

	public isMobile = this.mobileSate.asObservable();

	private checkIfMobile() {
		if (!this.checkPlatform.isPlatformBrowser) {
			return;
		}
		const ua = window.innerWidth;
		if (ua > 768) {
			this.mobileSate.next(false);
		} else {
			this.mobileSate.next(true);
		}
	}
}
