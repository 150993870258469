import {
	Directive,
	ElementRef,
	OnInit,
	OnChanges,
	Input,
	OnDestroy
} from "@angular/core";
import { Router } from "@angular/router";

import { CheckPlatformService } from "@shared/services/check-platform.service";

@Directive({ selector: "[dynamicInnerHtml]" })
export class DynamicInnerHtmlDirective implements OnInit, OnChanges, OnDestroy {
	private containerElement: HTMLElement;

	constructor(
		private router: Router,
		containerElement: ElementRef,
		private checkPlatform: CheckPlatformService
	) {
		this.containerElement = containerElement.nativeElement;
	}

	@Input()
	content: any;

	ngOnInit() {
		// only init when we are in browser
		if (this.checkPlatform.isPlatformBrowser) {
			this.updateDynamicContent(this.content);
		} else {
			this.addStaticContent(this.content);
		}
	}
	ngOnChanges() {
		if (this.checkPlatform.isPlatformBrowser) {
			this.updateDynamicContent(this.content);
		}
	}
	ngOnDestroy() {
		if (this.checkPlatform.isPlatformBrowser) {
			this.removeEventListener();
		}
	}

	updateDynamicContent(content: any) {
		// insert HTML to DOM
		this.containerElement.innerHTML = content;
		// query anchors
		const anchors = this.containerElement.querySelectorAll("a[href]");
		Array.from(anchors).forEach(el => {
			el.addEventListener("click", e => this.dynamicLinkHandler(el, e));
		});
	}
	removeEventListener() {
		const anchors = this.containerElement.querySelectorAll("a[href]");
		Array.from(anchors).forEach(el => {
			el.removeEventListener("click", e => this.dynamicLinkHandler(el, e));
		});
	}

	dynamicLinkHandler(el: any, e: any) {
		if (
			el.getAttribute("target") != "_blank" &&
			el.getAttribute("href").substring(0, 4) != "tel:" &&
			el.getAttribute("href").substring(0, 7) != "mailto:"
		) {
			e.preventDefault();
			let url = el.getAttribute("href");
			url = url.replace(/https?:\/\/[^\/]+/i, "");
			url = url.replace(/http?:\/\/[^\/]+/i, "");
			url = url.replace(/\/$/, "");
			console.log("INTERNAL ROUTING");
			this.router.navigateByUrl(url);
		}
	}

	addStaticContent(content: any) {
		this.containerElement.innerHTML = content;
	}
}
