import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class CheckPlatformService {
	constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

	private _isPlatformBrowser: boolean;
	public get isPlatformBrowser() {
		if (!this._isPlatformBrowser) {
			this._isPlatformBrowser = isPlatformBrowser(this.platformId);
		}
		return this._isPlatformBrowser;
	}
}
