import { Component, OnInit, Input, ElementRef } from "@angular/core";

import * as SVG from "svg.js";
import { CheckPlatformService } from "@shared/services/check-platform.service";

@Component({
	selector: "icon-eye",
	template: ``,
	styleUrls: ["./icon-eye.component.scss"]
})
export class IconEyeComponent implements OnInit {
	canvas;
	closedEye;

	eye;
	backgroundColor: string = "black";

	@Input("openEye")
	set setState(state) {
		if (this.closedEye && this.checkPlatform.isPlatformBrowser) {
			this.openEye(state);
		}
	}

	constructor(
		private elRef: ElementRef,
		private checkPlatform: CheckPlatformService
	) {
		if (!this.checkPlatform.isPlatformBrowser) return;
		this.canvas = SVG(this.elRef.nativeElement).size(64, 64);
		this.canvas.viewbox(0, 0, 64, 64);
		this.canvas.fill({ color: "currentColor" });
		this.canvas.path(
			"M32,14C14.33,14,0,32.5,0,32.5S14.33,51,32,51,64,32.5,64,32.5,49.67,14,32,14Zm0,31A13,13,0,1,1,45,32,13,13,0,0,1,32,45Z"
		);
		this.canvas.circle(12).attr({ cx: 32, cy: 32 });
		console.log("BG COLOR", this.backgroundColor);
	}

	ngOnInit() {
		if (!this.checkPlatform.isPlatformBrowser) return;
		this.closedEye = this.canvas.path(
			"M32,14.01961c16.5,0,30.07,16.09039,31.81,18.2404C62.07,30.11,48.5,14,32,14S1.93,30.11.19,32.26C1.93,30.11,15.5,14.01961,32,14.01961Z"
		);
	}
	openEye(state) {
		if (state) {
			this.closedEye
				.animate(300, "<>", 0)
				.plot(
					"M32,14.01961c16.5,0,30.07,16.09039,31.81,18.2404C62.07,30.11,48.5,14,32,14S1.93,30.11.19,32.26C1.93,30.11,15.5,14.01961,32,14.01961Z"
				);
		} else {
			this.closedEye
				.animate(300, "<>", 0)
				.plot(
					"M32,44c14.3,0,26.68-7.93,31.81-11.74C62.07,30.11,48.5,14,32,14S1.93,30.11.19,32.26C5.33,36.07,17.71,44,32,44Z"
				);
		}
	}
}
