import { Component, ChangeDetectionStrategy, HostBinding } from "@angular/core";
import {
	Router,
	ActivatedRoute,
	Event,
	NavigationStart,
	NavigationEnd
} from "@angular/router";
import { CheckPlatformService } from "@shared/services/check-platform.service";
import { ScrollService } from "@shared/services/scroll.service";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";

interface Window {
	chrome?: any;
	safari?: any;
	MSInputMethodContext?: any;
}
interface Document {
	documentMode?: any;
}
declare const window: Window;
declare const document: Document;
// declare const ga: Function;

@Component({
	selector: "ng-philoneos",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
	constructor(
		private router: Router,
		private checkPlatform: CheckPlatformService,
		private scrollService: ScrollService,
		private googleAnalytics: Angulartics2GoogleAnalytics
	) {
		if (!this.checkPlatform.isPlatformBrowser) return;

		googleAnalytics.startTracking();

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				// ga("send", "pageview", event.urlAfterRedirects);
				setTimeout(() => {
					this.scrollService.scrollToTop();
				}, 400);
			}
		});
	}

	@HostBinding("class.chrome")
	isChromium = this.checkPlatform.isPlatformBrowser ? !!window.chrome : false;
	@HostBinding("class.safari")
	isSafari = this.checkPlatform.isPlatformBrowser ? !!window.safari : false;
	@HostBinding("class.ie11")
	isIE11 = this.checkPlatform.isPlatformBrowser
		? !!window.MSInputMethodContext && !!document.documentMode
		: false;
}
